import Vue from "vue";
import Lang from "lang.js";
import BootstrapVue from "bootstrap-vue";
// import BookingForm from "./components/BookingForm.vue";
// import ShopForm from './components/ShopForm.vue'
// import HomeHeader from './components/HomeHeader.vue'
import Header from "./components/Header.vue";
import RoomSlider from "./components/RoomSlider.vue";

Vue.use(BootstrapVue);

// Vue.component('home-header', HomeHeader)
Vue.component("default-header", Header);
Vue.component("room-slider", RoomSlider);
// Vue.component("booking-form", BookingForm);
// Vue.component('shop-form', ShopForm)

const default_locale = window.default_language;
const fallback_locale = window.fallback_locale;
const messages = window.messages;

Vue.prototype._ = _;
Vue.prototype.trans = new Lang({
  messages: messages,
  locale: default_locale,
  fallback: fallback_locale,
});

Vue.mixin({
  methods: {
    route: route,
  },
});

const app = new Vue({
  el: "#app",
});

window.app = app;
