
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./vue');

let scrollFromTop = document.documentElement.scrollTop,
    navbar = document.querySelector('.navbar'),
    mainNav = document.querySelector('.main-menu'),
    mainNavTrigger = document.querySelector('.main-menu-trigger'),
    mainNavInTransition = false,
    langNav = document.querySelector('.lang-menu'),
    langNavTrigger = document.querySelector('.lang-trigger'),
    langNavInTransition = false,
    scrollClass = 'is-scrolling',
    activeClass = 'is-active',
    submenuTriggers = document.querySelectorAll('[submenu-trigger]'),
    bodyEl = document.querySelector('body'),
    noScrollClass = 'no-scroll'

if (scrollFromTop > 0) {
    if (navbar) {
        navbar.classList.add(scrollClass)
    }
}

window.onscroll = function () {
    let el = document.scrollingElement || document.documentElement
    let scrollPosition = el.scrollTop

    if (navbar) {
        if (scrollPosition > 0 && ! navbar.classList.contains(scrollClass)) {
            navbar.classList.add(scrollClass)
        } else if (scrollPosition == 0 && navbar.classList.contains(scrollClass)) {
            navbar.classList.remove(scrollClass)
        }
    }
}

function handleNavTransitionEvent(e) {
    mainNavInTransition = false
}

function handleLangNavTransitionEvent(e) {
    langNavInTransition = false
}

if (mainNavTrigger) {
    mainNavTrigger.onclick = function () {
        if (mainNavInTransition === false) {
            mainNavInTransition = true

            if (! mainNav.classList.contains(activeClass)) {
                mainNav.classList.add(activeClass)
                mainNavTrigger.classList.add(activeClass)
                navbar.classList.add(activeClass)
                bodyEl.classList.add(noScrollClass)

                mainNav.addEventListener('transitionend', handleNavTransitionEvent, false)
            } else {
                mainNav.classList.remove(activeClass)
                mainNavTrigger.classList.remove(activeClass)
                navbar.classList.remove(activeClass)
                bodyEl.classList.remove(noScrollClass)

                mainNav.addEventListener('transitionend', handleNavTransitionEvent, false)
            }
        }
    }
}

if (langNavTrigger) {
    langNavTrigger.onclick = function () {
        if (langNavInTransition === false) {
            langNavInTransition = true

            if (! langNav.classList.contains(activeClass)) {
                langNav.classList.add(activeClass)
                langNavTrigger.classList.add(activeClass)

                langNav.addEventListener('transitionend', handleLangNavTransitionEvent, false)
            } else {
                langNav.classList.remove(activeClass)
                langNavTrigger.classList.remove(activeClass)

                langNav.addEventListener('transitionend', handleLangNavTransitionEvent, false)
            }
        }
    }
}

for (let i in submenuTriggers) {
    if (submenuTriggers.hasOwnProperty(i)) {
        submenuTriggers[i].onclick = toggleSubmenu
    }
}

function toggleSubmenu(e) {
    e.preventDefault()

    let submenus = document.querySelectorAll('.submenu')

    for (let i = 0; i < submenus.length; i++) {
        if (submenuTriggers[i] == this) {
            continue;
        }

        submenus[i].classList.remove(activeClass)
        submenus[i].style.overflow = null
        submenus[i].style.maxHeight = null
        submenuTriggers[i].classList.remove('submenu-active')
    }

    let submenuName = this.getAttribute('submenu-trigger'),
        submenu = document.querySelector('[data-submenu='+submenuName+']')

    this.classList.toggle('submenu-active')

    if (submenu.style.maxHeight) {
        submenu.style.maxHeight = null
        submenu.style.overflow = null
        submenu.classList.remove(activeClass)
        navbar.classList.remove('active-submenu')
    } else {
        submenu.style.maxHeight = submenu.scrollHeight + 'px'
        submenu.style.overflow = 'visible'
        submenu.classList.add(activeClass)
        navbar.classList.add('active-submenu')
    }
}
