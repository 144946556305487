<template>
  <section class="section bg-info no-gap room-slider">
    <div class="container-fluid">
      <div class="row align-items-xl-center">
        <div class="col-12 col-md-6 container-col _overlap pr-md-0">
          <div class="card bg-white _shadow-5">
            <div class="card-body slider">
              <transition-group
                name="room-slide"
                mode="out-in"
                enter-class="slide-in"
                leave-class="slide-out"
                enter-active-class="animated"
                leave-active-class="animated slide-out-active"
                v-on:after-enter="afterEnter"
                v-on:before-leave="beforeLeave"
              >
                <div
                  class="slide room-slide"
                  v-for="(slide, index) in slides"
                  v-if="index == active - 1"
                  :key="'key-' + index"
                >
                  <h5 class="section-desc _center">{{ trans.get('text.home.slider.accomodation') }}</h5>
                  <h2
                    class="text-center text-black space-b"
                    v-html="slide.title"
                  ></h2>

                  <p class="text-center" v-text="slide.desc"></p>

                  <div
                    class="
                      buttons
                      space-t
                      d-flex
                      align-items-center
                      justify-content-center
                      flex-column flex-sm-row
                    "
                  >
                    <a
                      :href="slide.href"
                      class="btn btn-outline-primary mb-3 mb-sm-0 mr-sm-3"
                      >{{ trans.get('text.home.slider.details') }}</a
                    >
                    <a
                      :href="slide.booking_link"
                      target="_blank"
                      class="btn btn-outline-primary"
                      >{{ trans.get('text.home.slider.book') }}</a
                    >
                  </div>
                </div>
              </transition-group>
            </div>

            <div class="room-slider-nav">
              <button class="left-nav" @click="slide(-1)"></button>
              <button class="right-nav" @click="slide(1)"></button>
            </div>
          </div>
        </div>
        <figure
          class="col-12 col-md-6 _cover _bg bg-max-height"
          :style="activeRoomImage"
        ></figure>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    items: Array,
  },

  data() {
    return {
      slides: [],
      slidesCount: 0,
      active: 1,
      inTransition: false,
      activeRoomImage: "",
    };
  },

  mounted() {
    this.slides = this.items;
    this.slidesCount = this.slides.length;

    this.activeRoomImage = this.setCover(this.slides[this.active - 1].hero);
  },

  methods: {
    slide(amount) {
      let newActive;
      const newIndex = this.active + amount;
      if (this.inTransition === false) {
        if (newIndex > this.slidesCount) newActive = 1;
        if (newIndex === 0) newActive = this.slidesCount;
        this.active = newActive || newIndex;
        this.activeRoomImage = this.setCover(this.slides[this.active - 1].hero);
      }
    },

    jump(index) {
      if (this.inTransition === false) {
        this.active = index;
      }
    },

    afterEnter(el) {
      this.inTransition = false;
    },

    beforeLeave(el) {
      this.inTransition = true;
    },

    setCover(img) {
      if (img) {
        return "background-image: url(" + img + ");";
      }
    },
  },
};
</script>
