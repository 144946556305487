<template>
  <header>
    <div
      class="header _cover _overlay d-flex align-items-center"
      :class="!static ? '_full-h' : ''"
      :style="setCover(cover)"
    >
      <a :href="index" class="logo-c">
        <figure>
          <img src="/svg/verbenicum-logo.svg" alt="Hotel Verbenicum" />
        </figure>
        <figure>
          <img src="/svg/verbenicum-text.svg" alt="Hotel Verbenicum" />
        </figure>
      </a>

      <h1
        class="
          header-title
          title-with-bg
          text-left
          m-0
          d-flex
          align-items-start
          justify-content-center
          flex-column
        "
        v-html="title"
        v-if="!program && !static"
      ></h1>

      <div
        class="
          header-title
          title-with-bg
          _program
          d-flex
          align-items-center
          justify-content-center
          flex-column
        "
        v-html="title"
        v-if="program && !static"
      ></div>

      <h1
        class="
          header-title
          text-white text-center
          m-0
          d-flex
          align-items-center
          justify-content-center
          flex-column
          w-100
        "
        v-html="title"
        v-if="!program && static"
      ></h1>
    </div>

    <div class="slider-nav _links" v-if="links">
      <a class="slide-prev" v-if="links.prev" :href="links.prev.href">
        <span class="label" v-text="links.prev.name"></span>
      </a>
      <a class="slide-next" v-if="links.next" :href="links.next.href">
        <span class="label" v-text="links.next.name"></span>
      </a>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    cover: String,
    title: String,
    links: Object,
    program: Boolean,
    static: Boolean,
  },

  data() {
    return {
      index: route("index"),
    };
  },

  methods: {
    setCover(img) {
      if (img) {
        return "background-image: url(" + img + ")";
      }
    },
  },
};
</script>
